import * as S from './styles'

import {
  Autocomplete,
  Avatar,
  Divider,
  IconButton,
  ImageListItem,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
} from '@mui/material'
import {
  DeliveryMethod,
  Product,
  ProductStatus,
  ProductVariant,
  ISelectItem,
} from '@monorepo/interfaces'
import { ProductSchema, initialValues } from './ManageFormHelpers'
import { Prompt, useHistory, useParams } from 'react-router-dom'
import React, { useCallback, useEffect, useState } from 'react'
import { getIn, useFormik } from 'formik'

import CreatableSelect from 'react-select/creatable'
import DeleteIcon from '@mui/icons-material/Delete'
import { DropzoneArea } from 'material-ui-dropzone'
import { ManageCard, TimeLineList } from '../../../organisms'
import ManageTemplate from '../../Manage'
import { PageSpinner } from '@monorepo/components'
import axios from 'axios'
import { colors } from '@monorepo/theme'
import {
  http,
  ProductHelper,
  DealerHelper,
  formatCurrency,
  isDate,
  utcDate,
  localDate,
  ChannelHelper,
  KeycloakHelper,
} from '@monorepo/infra'
import { productStatus } from '../../../../database'
import { useKeycloak } from '@react-keycloak/web'
import cloneDeep from 'lodash/cloneDeep'

export enum ManageProductsEditingMode {
  CREATING = 0,
  EDITING,
}

const channels: ISelectItem[] = ChannelHelper.getChannelSelectItems()

const eligibleDealerTypes = ['preferred_dealer']
const dealerTypes = DealerHelper.getDealerOptions().filter((item) =>
  eligibleDealerTypes.find((dt) => dt === item.id)
)
const sizes = ProductHelper.getSizeOptions()

const vendors: ISelectItem[] = [
  { value: 'PURE', id: '1' },
  { value: 'DSI', id: '2' },
]
const LoadingStatus = {
  INITIAL: 'INITIAL',
  FETCHING: 'FETCHING',
  FETCHING_SUCCESS: 'FETCHING_SUCCESS',
  FETCHING_ERROR: 'FETCHING_ERROR',
  SAVING: 'SAVING',
  SAVING_SUCCESS: 'SAVING_SUCCESS',
  SAVING_ERROR: 'SAVING_ERROR',
  UPLOADING: 'UPLOADING',
  UPLOADING_SUCCESS: 'UPLOADING_SUCCESS',
  UPLOADING_ERROR: 'UPLOADING_ERROR',
}
export interface ManageProductsProps {
  editingMode: ManageProductsEditingMode
}

type ProductType = Omit<
  Product,
  'createdBy' | 'createdOn' | 'updatedBy' | 'updatedOn'
> & {
  variantsForm: ProductVariant[]
}

const ManageProducts: React.FC<ManageProductsProps> = ({ editingMode }) => {
  const [loadingStatus, setLoadingStatus] = useState<string>(
    LoadingStatus.INITIAL
  )

  const [product, setProduct] = useState<ProductType>(initialValues)
  const [tagsOptions, setTagsOptions] = useState<
    { value: string; label: string }[]
  >([])
  const history = useHistory()
  const { id: productId } = useParams<{ id: string }>()
  const { keycloak } = useKeycloak()
  const user = KeycloakHelper.getTokenParsed(keycloak)

  const handleGoBack = useCallback(() => {
    history.push('/products')
  }, [history])

  const handleCreateProduct = useCallback(
    (values: ProductType) => {
      setLoadingStatus(LoadingStatus.SAVING)

      http
        .post({ url: `/product`, data: values })
        .then((response) => {
          const product = response?.data as ProductType
          history.push(`/products/edit/${product?._id}`)
        })
        .catch((error) => {
          setLoadingStatus(LoadingStatus.SAVING_ERROR)
          throw error
        })
    },
    [history]
  )

  const handleDuplicateProduct = async (product: ProductType) => {
    setLoadingStatus(LoadingStatus.SAVING)

    const clonedProduct = cloneDeep(product)

    delete clonedProduct._id
    clonedProduct.title = `Cloned ${clonedProduct.title}`
    clonedProduct.status = ProductStatus.DRAFT
    clonedProduct.sku = `cloned${new Date().getTime().toString()}`
    clonedProduct.variants = clonedProduct.variants.map((x) => {
      const { inDesignFile, downloadFile, ...rest } = x
      return rest
    })

    try {
      const response = await http.post({ url: `/product`, data: clonedProduct })
      const createdProduct = response?.data as ProductType
      setLoadingStatus(LoadingStatus.SAVING_SUCCESS)
      history.push(`/products/edit/${createdProduct?._id}`)
      window.location.reload()
    } catch (error) {
      console.error(error)
      setLoadingStatus(LoadingStatus.SAVING_ERROR)
      throw error
    }
  }

  const handleSaveVariants = () => {
    formik.setFieldValue('variants', [
      ...formik.values.variants,
      ...formik.values.variantsForm,
    ])
    // formik.validateForm().then((result) => console.log(result))
    formik.setFieldValue('variantsForm', [])
  }
  const handleDeleteVariants = (index: number) => {
    formik.setFieldValue('variants', [
      ...formik.values.variants.filter((variant, idx) => idx !== index),
    ])
  }
  const handleDeleteVariantInDesign = (index: number) => {
    formik.setFieldValue('variants', [
      ...formik.values.variants.map((variant, idx) => {
        if (idx === index) {
          delete variant.inDesignFile
        }
        return variant
      }),
    ])
    formik.setFieldValue('variantsForm', [
      ...formik.values.variantsForm.map((variant, idx) => {
        if (idx === index) {
          delete variant.inDesignFile
        }
        return variant
      }),
    ])
  }
  const handleDeleteVariantDownload = (index: number) => {
    formik.setFieldValue('variants', [
      ...formik.values.variants.map((variant, idx) => {
        if (idx === index) {
          delete variant.downloadFile
        }
        return variant
      }),
    ])
    formik.setFieldValue('variantsForm', [
      ...formik.values.variantsForm.map((variant, idx) => {
        if (idx === index) {
          delete variant.downloadFile
        }
        return variant
      }),
    ])
  }
  const handleDeleteVariantMediaItem = (index: number, index2: number) => {
    formik.setFieldValue('variantsForm', [
      ...formik.values.variantsForm.map((variant, idx) => {
        if (idx === index) {
          variant.media = variant.media.filter((media, idx2) => idx2 !== index2)
        }
        return variant
      }),
    ])
  }
  const handleUploadFiles = (
    file: File,
    inDesignFile: boolean,
    fieldName: string,
    index: number,
    fileType?: string
  ) => {
    if (file) {
      setLoadingStatus(LoadingStatus.UPLOADING)
      axios.create({
        baseURL: process.env.REACT_APP_REST_BASE_URL,
      })
      const url = `${process.env.REACT_APP_MEDIA_URL}`
      const formData = new FormData()
      formData.append('file', file)
      formData.append('isInDesign', inDesignFile ? 'true' : 'false')
      formData.append('tags', formik.values.tags.join(','))
      formData.append('email', user?.email)
      formData.append('mediaType', fileType || '')
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      }
      axios
        .post(url, formData, config)
        .then((success) => {
          if (fieldName === 'media') {
            formik.setFieldValue(`variantsForm[${index}].${fieldName}`, [
              ...formik.values.variantsForm[index].media,
              success?.data.path,
            ])
          } else {
            formik.setFieldValue(
              `variantsForm[${index}].${fieldName}`,
              success?.data.path
            )
          }
          setLoadingStatus(LoadingStatus.UPLOADING_SUCCESS)
        })
        .catch((error) => {
          handleDeleteVariants(index)
          setLoadingStatus(LoadingStatus.UPLOADING_ERROR)
        })
    }
  }

  const { errors, touched, handleBlur, ...formik } = useFormik({
    enableReinitialize: true,
    validationSchema: ProductSchema,
    initialValues: product,
    validateOnBlur: true,
    onSubmit: (values, { validateForm }) => {
      validateForm().then(() => {
        handleSubmit(values)
      })
    },
  })

  const handleEditProduct = useCallback(
    (values: ProductType) => {
      setLoadingStatus(LoadingStatus.SAVING)

      http
        .patch({ url: `/product/${productId}`, data: values })
        .then((data) => {
          setLoadingStatus(LoadingStatus.SAVING_SUCCESS)
          setProduct(patchValuesToEdit(data.data as ProductType))
          formik.resetForm({ values })
        })
        .catch(() => {
          setLoadingStatus(LoadingStatus.SAVING_ERROR)
        })
    },
    [productId, formik]
  )

  const handleSubmit = useCallback(
    (values: ProductType) => {
      const valuesToSave = patchValuesToSave(values)

      if (editingMode === ManageProductsEditingMode.CREATING) {
        handleCreateProduct(valuesToSave)
        return
      }

      if (editingMode === ManageProductsEditingMode.EDITING) {
        handleEditProduct(valuesToSave)
        return
      }
    },
    [editingMode, handleCreateProduct, handleEditProduct]
  )

  const handleSaveDraft = useCallback(
    (values: ProductType) => {
      handleSubmit(values)
    },
    [handleSubmit]
  )

  const handleDiscardChanges = useCallback(() => {
    formik.setValues(product)
  }, [product, formik])

  useEffect(() => {
    const isEditing =
      productId && editingMode === ManageProductsEditingMode.EDITING
    const isInitializing = loadingStatus === LoadingStatus.INITIAL
    const shouldSearchProduct = isEditing && isInitializing
    if (!shouldSearchProduct) return

    setLoadingStatus(LoadingStatus.FETCHING)

    http
      .get({ url: `/product/${productId}` })
      .then((response) => {
        // product.variants.map((variant: ProductVariant) => {
        //   if (variant?.price) return formatCurrency(variant?.price)
        //   return null
        // })
        const product = response?.data as ProductType
        product.variantsForm = []
        setProduct(patchValuesToEdit(product))
        setLoadingStatus(LoadingStatus.FETCHING_SUCCESS)
      })
      .catch(() => {
        setLoadingStatus(LoadingStatus.FETCHING_ERROR)
      })
  }, [editingMode, productId, formik, loadingStatus])

  const patchValuesToEdit = (product: ProductType): ProductType => {
    if (product.activationDate) {
      product.activationDate = localDate(product.activationDate)
    }
    if (product.expirationDate) {
      product.expirationDate = localDate(product.expirationDate)
    }

    return product
  }

  const patchValuesToSave = (values: ProductType): ProductType => ({
    ...values,
    ...(isDate(values.activationDate) && {
      activationDate: utcDate(values.activationDate as Date),
    }),
    ...(isDate(values.expirationDate) && {
      expirationDate: utcDate(values.expirationDate as Date),
    }),
  })

  useEffect(() => {
    http
      .get({ url: '/product/tags' })
      .then((response) => {
        if (Array.isArray(response?.data)) {
          setTagsOptions(
            response?.data.map((item) => ({ value: item, label: item }))
          )
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  return (
    <div>
      <ManageTemplate
        onGoBack={handleGoBack}
        backText="Back to all Products"
        titleText={`${
          editingMode === ManageProductsEditingMode.CREATING
            ? 'Create'
            : 'Manage'
        } Product`}
        headerRightSideChildren={
          <S.ButtonsContainer>
            {!formik.dirty && formik.isValid && (
              <S.FormButton
                onClick={() => handleDuplicateProduct(formik.values)}
              >
                Duplicate
              </S.FormButton>
            )}
            {formik.dirty && (
              <>
                <S.FormButton onClick={handleDiscardChanges}>
                  Discard
                </S.FormButton>
                {formik.isValid && (
                  <>
                    {editingMode === ManageProductsEditingMode.CREATING && (
                      <S.FormButton
                        backgroundColor={colors.mediumGrey}
                        onClick={() => {
                          const draftStatus = productStatus.find(
                            ({ value }) =>
                              value === ProductStatus.DRAFT.toString()
                          )
                          formik.setFieldValue(
                            'status',
                            parseInt(String(draftStatus?.value))
                          )

                          handleSaveDraft({
                            ...formik.values,
                            status: parseInt(ProductStatus.DRAFT.toString()),
                          })
                        }}
                      >
                        Save as Draft
                      </S.FormButton>
                    )}
                    <S.FormButton
                      backgroundColor={colors.blue}
                      onClick={() => handleSubmit(formik.values)}
                    >
                      Save
                    </S.FormButton>
                  </>
                )}
              </>
            )}
          </S.ButtonsContainer>
        }
      >
        {[
          LoadingStatus.FETCHING,
          LoadingStatus.SAVING,
          LoadingStatus.UPLOADING,
        ].includes(loadingStatus) ? (
          <PageSpinner />
        ) : (
          <S.ColumnsContainer>
            <Prompt
              when={!!formik.dirty}
              message={
                'You have made changes to this product. If you leave, any unsaved changes will be lost. Are you sure?'
              }
            />
            <S.LeftColumn>
              <div className="two-thirds">
                <ManageCard title="Title">
                  <S.TextInput
                    id="title"
                    name="title"
                    label="Title"
                    value={formik.values.title}
                    onChange={({ target: { value } }) =>
                      formik.setFieldValue('title', value)
                    }
                    onBlur={handleBlur}
                    invalid={!!errors.title && touched.title}
                    invalidMessage={errors.title}
                  />
                </ManageCard>
                <ManageCard title="Description">
                  <S.TextInput
                    id="description"
                    name="description"
                    label="Description"
                    value={formik.values.description}
                    onChange={({ target: { value } }) =>
                      formik.setFieldValue('description', value)
                    }
                    onBlur={handleBlur}
                    invalid={!!errors.description && touched.description}
                    invalidMessage={errors.description}
                  />
                </ManageCard>
                <ManageCard title="Delivery Method">
                  <fieldset>
                    <label>
                      <S.CheckboxInput
                        type="checkbox"
                        name="deliveryMethods"
                        value="1"
                        checked={formik.values?.deliveryMethods?.some(
                          (dm: number) => dm === 1
                        )}
                        onChange={(e: {
                          target: { checked: boolean; value: string }
                        }) => {
                          if (e.target.checked) {
                            formik.setFieldValue('deliveryMethods', [
                              ...formik.values.deliveryMethods,
                              parseInt(e.target.value),
                            ])
                          } else {
                            formik.setFieldValue(
                              'deliveryMethods',
                              formik.values.deliveryMethods.filter(
                                (dm: number) => dm !== parseInt(e.target.value)
                              )
                            )
                          }
                        }}
                      />
                      Print &amp; Ship
                      <S.CheckboxInput
                        type="checkbox"
                        name="deliveryMethods"
                        value="2"
                        checked={formik.values?.deliveryMethods?.some(
                          (dm: number) => dm === 2
                        )}
                        onChange={(e: {
                          target: { checked: boolean; value: string }
                        }) => {
                          if (e.target.checked) {
                            formik.setFieldValue('deliveryMethods', [
                              ...formik.values.deliveryMethods,
                              parseInt(e.target.value),
                            ])
                          } else {
                            formik.setFieldValue(
                              'deliveryMethods',
                              formik.values.deliveryMethods.filter(
                                (dm: number) => dm !== parseInt(e.target.value)
                              )
                            )
                          }
                        }}
                      />
                      Download
                      <S.CheckboxInput
                        type="checkbox"
                        name="deliveryMethods"
                        value="3"
                        checked={formik.values?.deliveryMethods?.some(
                          (dm: number) => dm === 3
                        )}
                        onChange={(e: {
                          target: { checked: boolean; value: string }
                        }) => {
                          if (e.target.checked) {
                            formik.setFieldValue('deliveryMethods', [
                              ...formik.values.deliveryMethods,
                              parseInt(e.target.value),
                            ])
                          } else {
                            formik.setFieldValue(
                              'deliveryMethods',
                              formik.values.deliveryMethods.filter(
                                (dm: number) => dm !== parseInt(e.target.value)
                              )
                            )
                          }
                        }}
                      />
                      Custom
                    </label>
                    {touched.deliveryMethods && errors.deliveryMethods && (
                      <div>{errors.deliveryMethods}</div>
                    )}
                  </fieldset>
                </ManageCard>
                <ManageCard title="SKU">
                  <S.TextInput
                    id="sku"
                    name="sku"
                    label="0000000000"
                    value={formik.values.sku}
                    onChange={({ target: { value } }) =>
                      formik.setFieldValue('sku', value)
                    }
                    onBlur={handleBlur}
                    invalid={!!errors.sku && touched.sku}
                    invalidMessage={errors.sku}
                  />
                </ManageCard>
                <ManageCard title="Product Variants">
                  <List>
                    {formik.values.variants.map((variant, index) => (
                      <>
                        <ListItem
                          secondaryAction={
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={() => {
                                handleDeleteVariants(index)
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          }
                          onClick={() => {
                            handleDeleteVariants(index)
                            formik.setFieldValue('variantsForm', [
                              {
                                title: variant.title,
                                description: variant.description,
                                media: variant.media,
                                inDesignFile: variant.inDesignFile,
                                downloadFile: variant.downloadFile,
                                size: variant.size,
                                price: variant.price,
                                deliveryMethods: [DeliveryMethod.PRINT],
                                printAndShipOptions:
                                  variant.printAndShipOptions,
                              },
                            ])
                          }}
                        >
                          <ListItemAvatar>
                            <Avatar>
                              <ImageListItem>
                                <img
                                  alt={variant.title}
                                  src={variant.media[0]}
                                />
                              </ImageListItem>
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              sizes.find(
                                (size) => size.id === variant.size.toString()
                              )?.value
                            }
                          />
                        </ListItem>
                        <Divider variant="inset" component="li" />
                      </>
                    ))}
                  </List>
                  <fieldset>
                    {formik.values?.variantsForm?.length > 0 ? (
                      <S.ButtonForm
                        type="button"
                        className="secondary"
                        onClick={() => {
                          handleSaveVariants()
                        }}
                      >
                        Save Variant
                      </S.ButtonForm>
                    ) : (
                      <></>
                    )}
                    {formik.values?.variantsForm?.map((_, index) => (
                      <div className="row" key={index}>
                        <ManageCard title="Title">
                          <div className="col">
                            <S.TextInput
                              name={`variantsForm[${index}].title`}
                              label=""
                              type="text"
                              value={formik.values.variantsForm[index].title}
                              onChange={({ target: { value } }) =>
                                formik.setFieldValue(
                                  `variantsForm[${index}].title`,
                                  value
                                )
                              }
                              onBlur={handleBlur}
                              invalid={
                                !!errors.description && touched.description
                              }
                              invalidMessage={errors.description}
                            />
                            {getIn(touched, `variantsForm[${index}].title`) &&
                              getIn(errors, `variantsForm[${index}].title`) && (
                                <div>
                                  {getIn(
                                    errors,
                                    `variantsForm[${index}].title`
                                  )}
                                </div>
                              )}
                          </div>
                        </ManageCard>
                        <ManageCard title="Size">
                          <div className="col">
                            <Autocomplete
                              id="sizes-outlined"
                              options={sizes}
                              getOptionLabel={(option: ISelectItem) =>
                                option.value
                              }
                              filterSelectedOptions
                              defaultValue={sizes.find(
                                (size) =>
                                  size.id ===
                                  formik.values?.variantsForm[
                                    index
                                  ].size.toString()
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  id="sizes"
                                  name="Sizes"
                                  label="Sizes"
                                  value={`variantsForm[${index}].size`}
                                />
                              )}
                              onBlur={handleBlur}
                              onChange={(event, values) => {
                                formik.setFieldValue(
                                  `variantsForm[${index}].size`,
                                  values && values?.id ? parseInt(values.id) : 0
                                )
                              }}
                            />
                          </div>
                        </ManageCard>
                        <div className="col">
                          <ManageCard title="Print &amp; Ship options">
                            <fieldset>
                              {formik.values.variantsForm[
                                index
                              ].printAndShipOptions?.map((_, index2) => (
                                <div className="row" key={index2}>
                                  <div className="col">
                                    <ManageCard title="COUNT">
                                      <S.TextInput
                                        name={`variantsForm[${index}].printAndShipOptions[${index2}].pieceCount`}
                                        label="100"
                                        type="number"
                                        onChange={({ target: { value } }) =>
                                          formik.setFieldValue(
                                            `variantsForm[${index}].printAndShipOptions[${index2}].pieceCount`,
                                            +value
                                          )
                                        }
                                        onBlur={handleBlur}
                                        value={
                                          formik.values?.variantsForm[index]
                                            .printAndShipOptions[index2]
                                            .pieceCount
                                        }
                                      />
                                      {getIn(
                                        touched,
                                        `variantsForm[${index}].printAndShipOptions[${index}].pieceCount`
                                      ) &&
                                        getIn(
                                          errors,
                                          `variantsForm[${index}].printAndShipOptions[${index}].pieceCount`
                                        ) && (
                                          <div>
                                            getIn( errors, `variantsForm[$
                                            {index}
                                            ].printAndShipOptions[$
                                            {index}
                                            ].pieceCount` )
                                          </div>
                                        )}
                                    </ManageCard>
                                  </div>
                                  <div className="col">
                                    <ManageCard title="PRICE">
                                      <S.TextInput
                                        name={`variantsForm[${index}].printAndShipOptions[${index2}].price`}
                                        label="100"
                                        type="text"
                                        onChange={({ target: { value } }) =>
                                          formik.setFieldValue(
                                            `variantsForm[${index}].printAndShipOptions[${index2}].price`,
                                            formatCurrency(value)
                                          )
                                        }
                                        onBlur={handleBlur}
                                        value={
                                          formik.values?.variantsForm[index]
                                            .printAndShipOptions[index2].price
                                        }
                                      />
                                      {getIn(
                                        touched,
                                        `variantsForm[${index}].printAndShipOptions[${index}].price`
                                      ) &&
                                        getIn(
                                          errors,
                                          `variantsForm[${index}].printAndShipOptions[${index}].price`
                                        ) && (
                                          <div>
                                            getIn( errors, `variantsForm[$
                                            {index}
                                            ].printAndShipOptions[$
                                            {index}
                                            ].price` )
                                          </div>
                                        )}
                                    </ManageCard>
                                  </div>
                                </div>
                              ))}
                              <S.ButtonForm
                                type="button"
                                className="secondary"
                                onClick={() =>
                                  formik.setFieldValue(
                                    `variantsForm[${index}].printAndShipOptions`,
                                    [
                                      ...formik.values.variantsForm[index]
                                        .printAndShipOptions,
                                      {
                                        pieceCount: 0,
                                        price: '0.00',
                                      },
                                    ]
                                  )
                                }
                              >
                                Add additional count and price
                              </S.ButtonForm>
                              <ManageCard title="Image and Tumbnail">
                                {formik.values.variantsForm[index].media
                                  .length > 0 && (
                                  <List>
                                    {formik.values.variantsForm[
                                      index
                                    ].media.map((mediaItem, index2) => (
                                      <>
                                        <ListItem
                                          secondaryAction={
                                            <IconButton
                                              edge="end"
                                              aria-label="delete"
                                              onClick={() => {
                                                handleDeleteVariantMediaItem(
                                                  index,
                                                  index2
                                                )
                                              }}
                                            >
                                              <DeleteIcon />
                                            </IconButton>
                                          }
                                        >
                                          <ListItemAvatar>
                                            <Avatar>
                                              <ImageListItem>
                                                <img
                                                  alt={
                                                    formik.values.variantsForm[
                                                      index
                                                    ].title
                                                  }
                                                  src={mediaItem}
                                                />
                                              </ImageListItem>
                                            </Avatar>
                                          </ListItemAvatar>
                                          <ListItemText
                                            primary={`Image ${index2 + 1}`}
                                          />
                                        </ListItem>
                                        <Divider
                                          variant="inset"
                                          component="li"
                                        />
                                      </>
                                    ))}
                                  </List>
                                )}
                                <DropzoneArea
                                  acceptedFiles={[
                                    'image/jpeg',
                                    'image/png',
                                    'image/bmp',
                                  ]}
                                  maxFileSize={100000000}
                                  filesLimit={1}
                                  onChange={(files) => {
                                    handleUploadFiles(
                                      files[0],
                                      false,
                                      'media',
                                      index,
                                      'thumb'
                                    )
                                  }}
                                ></DropzoneArea>
                              </ManageCard>
                              {!formik.values.variantsForm[index]
                                .inDesignFile ? (
                                !formik.values.variantsForm[index]
                                  .downloadFile ? (
                                  <ManageCard title="Add In Design File (optional)">
                                    <DropzoneArea
                                      acceptedFiles={[
                                        'application/zip',
                                        'application/x-zip-compressed',
                                        'multipart/x-zip',
                                        'application/octet-stream',
                                      ]}
                                      filesLimit={1}
                                      maxFileSize={100000000}
                                      onChange={(files) => {
                                        handleUploadFiles(
                                          files[0],
                                          true,
                                          'inDesignFile',
                                          index,
                                          'inDesign'
                                        )
                                      }}
                                    ></DropzoneArea>
                                  </ManageCard>
                                ) : null
                              ) : (
                                <List>
                                  <ListItem
                                    secondaryAction={
                                      <IconButton
                                        edge="end"
                                        aria-label="delete"
                                        onClick={() => {
                                          handleDeleteVariantInDesign(index)
                                        }}
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    }
                                  >
                                    <ListItemText
                                      primary={formik.values.variantsForm[
                                        index
                                      ].inDesignFile
                                        ?.split('/')
                                        .pop()}
                                    />
                                  </ListItem>
                                  <Divider variant="inset" component="li" />
                                </List>
                              )}
                              {!formik.values.variantsForm[index]
                                .downloadFile ? (
                                !formik.values.variantsForm[index]
                                  .inDesignFile ? (
                                  <ManageCard title="Add Download File (optional)">
                                    <DropzoneArea
                                      filesLimit={1}
                                      maxFileSize={100000000}
                                      onChange={(files) => {
                                        handleUploadFiles(
                                          files[0],
                                          false,
                                          'downloadFile',
                                          index,
                                          'download'
                                        )
                                      }}
                                    ></DropzoneArea>
                                  </ManageCard>
                                ) : null
                              ) : (
                                <List>
                                  <ListItem
                                    secondaryAction={
                                      <IconButton
                                        edge="end"
                                        aria-label="delete"
                                        onClick={() => {
                                          handleDeleteVariantDownload(index)
                                        }}
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    }
                                  >
                                    <ListItemText
                                      primary={formik.values.variantsForm[
                                        index
                                      ].downloadFile
                                        ?.split('/')
                                        .pop()}
                                    />
                                  </ListItem>
                                  <Divider variant="inset" component="li" />
                                </List>
                              )}
                            </fieldset>
                          </ManageCard>
                        </div>
                      </div>
                    ))}
                    <S.ButtonForm
                      type="button"
                      className="secondary"
                      onClick={() =>
                        formik.setFieldValue('variantsForm', [
                          ...formik.values?.variantsForm,
                          {
                            title: '',
                            description: '',
                            media: [],
                            size: 0,
                            price: '',
                            deliveryMethods: [DeliveryMethod.PRINT],
                            printAndShipOptions: [
                              { pieceCount: 0, price: '0.00' },
                            ],
                          },
                        ])
                      }
                    >
                      Add Product Variant
                    </S.ButtonForm>
                  </fieldset>
                </ManageCard>
                <ManageCard title="Product Timeline">
                  <TimeLineList history={product.history} />
                </ManageCard>
              </div>
            </S.LeftColumn>
            <S.RightColumn>
              <div className="one-third">
                <ManageCard title="Product Status">
                  <S.TextInput
                    as="select"
                    name="status"
                    value={formik.values.status}
                    onChange={(e: { target: { value: string } }) => {
                      formik.setFieldValue('status', parseInt(e.target.value))
                    }}
                  >
                    <option value="1">Active</option>
                    <option value="2">Draft</option>
                    <option value="3">Archived</option>
                  </S.TextInput>
                  {touched.status && errors.status && (
                    <div>{errors.status}</div>
                  )}
                </ManageCard>
                <ManageCard title="Product Life">
                  <fieldset>
                    <ManageCard title="Activation Date (optional)">
                      <S.StartEndDateContainer>
                        <S.KeyboardDateTimePicker
                          autoOk
                          variant="inline"
                          inputVariant="outlined"
                          label="Select a date & time"
                          format="MM/dd/yy hh:mm a"
                          // minDate={formik.values.activationDate}
                          value={formik.values.activationDate ?? null}
                          onChange={(date) => {
                            formik.setFieldValue('activationDate', date)
                          }}
                          ampm
                        />
                      </S.StartEndDateContainer>
                    </ManageCard>
                    <ManageCard title="Expiration Date (optional)">
                      <S.StartEndDateContainer>
                        <S.KeyboardDateTimePicker
                          autoOk
                          variant="inline"
                          inputVariant="outlined"
                          label="Select a date & time"
                          format="MM/dd/yy hh:mm a"
                          // minDate={formik.values.activationDate}
                          value={formik.values.expirationDate ?? null}
                          onChange={(date) =>
                            formik.setFieldValue('expirationDate', date)
                          }
                          ampm
                        />
                      </S.StartEndDateContainer>
                    </ManageCard>
                  </fieldset>
                </ManageCard>
                <ManageCard title="Organization (Admin view only)">
                  <ManageCard title="Vendor">
                    <Autocomplete
                      id="vendors-outlined"
                      value={vendors.find(
                        (vendor) => +vendor.id === formik.values.vendor
                      )}
                      options={vendors}
                      getOptionLabel={(option: ISelectItem) => option.value}
                      filterSelectedOptions
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          id="vendors"
                          name="Vendors"
                          label="Vendors"
                        />
                      )}
                      onBlur={handleBlur}
                      onChange={(event, values) => {
                        formik.setFieldValue(
                          'vendor',
                          values?.id ? parseInt(values.id) : 0
                        )
                      }}
                    />
                  </ManageCard>
                </ManageCard>
                <ManageCard title="Classification">
                  <fieldset>
                    <ManageCard title="Channels">
                      <Autocomplete
                        multiple
                        id="channels-outlined"
                        options={channels}
                        value={channels.filter(({ id }) =>
                          formik.values.channels?.includes(id)
                        )}
                        getOptionLabel={(option: ISelectItem) => option.value}
                        filterSelectedOptions
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            id="channels"
                            name="Channels"
                            label="Channels"
                          />
                        )}
                        onBlur={handleBlur}
                        onChange={(event, values) => {
                          formik.setFieldValue(
                            'channels',
                            values.map((value) => {
                              return value.id
                            })
                          )
                        }}
                      />
                    </ManageCard>
                    <ManageCard title="DealerTypes">
                      <Autocomplete
                        multiple
                        id="dealerTypes-outlined"
                        options={dealerTypes}
                        value={dealerTypes.filter(({ id }: { id: string }) =>
                          formik.values.dealerTypes?.includes(id)
                        )}
                        getOptionLabel={(option: ISelectItem) => option.value}
                        filterSelectedOptions
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            id="dealerTypes"
                            name="DealerTypes"
                            label="Dealer Types"
                          />
                        )}
                        onBlur={handleBlur}
                        onChange={(event, values) => {
                          formik.setFieldValue(
                            'dealerTypes',
                            values.map((value) => {
                              return value.id
                            })
                          )
                        }}
                      />
                    </ManageCard>
                    <ManageCard title="Categories">
                      <S.InputTitle>Categories</S.InputTitle>
                      <CreatableSelect
                        name="categories"
                        placeholder="DIRECTV, AT&T"
                        isMulti
                        options={[]}
                        components={{
                          IndicatorSeparator: () => null,
                          DropdownIndicator: () => null,
                        }}
                        value={formik.values.categories.map((category) => ({
                          value: category,
                          label: category,
                        }))}
                        onChange={(newValue) =>
                          formik.setFieldValue(
                            'categories',
                            newValue.map(({ value }) => value)
                          )
                        }
                        styles={S.MultiSelectStyle}
                      />
                    </ManageCard>
                    <ManageCard title="Tags">
                      <S.InputTitle>Tags</S.InputTitle>
                      <CreatableSelect
                        name="tags"
                        placeholder="Bestseller, Featured"
                        isMulti
                        options={tagsOptions}
                        components={{
                          IndicatorSeparator: () => null,
                          DropdownIndicator: () => null,
                        }}
                        value={formik.values.tags.map((tag) => ({
                          value: tag,
                          label: tag,
                        }))}
                        onChange={(newValue) =>
                          formik.setFieldValue(
                            'tags',
                            newValue.map(({ value }) => value)
                          )
                        }
                        styles={S.MultiSelectStyle}
                      />
                    </ManageCard>
                  </fieldset>
                </ManageCard>
                <ManageCard title="ACB Media ID">
                  <S.TextInput
                    id="mediaIdACB"
                    name="mediaIdACB"
                    label="ACB Media ID"
                    value={formik.values.mediaIdACB}
                    onChange={({ target: { value } }) =>
                      formik.setFieldValue('mediaIdACB', value)
                    }
                    onBlur={handleBlur}
                    invalid={!!errors.mediaIdACB && touched.mediaIdACB}
                    invalidMessage={errors.mediaIdACB}
                  />
                </ManageCard>
                <ManageCard title="Search Terms">
                  <CreatableSelect
                    name="searchTerms"
                    placeholder="Rodeo, Festival, Music"
                    isMulti
                    value={formik.values.searchTerms?.map((searchTerm) => ({
                      value: searchTerm,
                      label: searchTerm,
                    }))}
                    components={{
                      IndicatorSeparator: () => null,
                      DropdownIndicator: () => null,
                    }}
                    onChange={(newValue) =>
                      formik.setFieldValue(
                        'searchTerms',
                        newValue.map(({ value }) => value)
                      )
                    }
                    styles={S.MultiSelectStyle}
                  />
                </ManageCard>
                <ManageCard title="Notes">
                  <S.TextInput
                    id="notes"
                    name="notes"
                    label="Add a note"
                    onBlur={handleBlur}
                    onChange={({ target: { value } }) =>
                      formik.setFieldValue('notes', value)
                    }
                    invalid={!!errors.searchTerms && touched.searchTerms}
                    invalidMessage={errors.searchTerms}
                  />
                  {touched.notes && errors.notes && <div>{errors.notes}</div>}
                </ManageCard>
              </div>
            </S.RightColumn>
          </S.ColumnsContainer>
        )}
      </ManageTemplate>
    </div>
  )
}
export default ManageProducts
